.root {
  display: flex;
  flex-wrap: wrap;
}

.spacer {
  display: block;
  width: 20px;
}

.word {
  margin: 0 10px;
  flex-grow: 1;
}
