.root,
.root:visited {
  margin: 0.5rem;
  animation: var(--animate-when-solved);
  background-color: var(--button-color);
  border: 1px solid var(--button-text-color);
  color: var(--button-text-color);
  padding: 10px;
  text-decoration: none;
  width: 9rem;
  text-align: center;
  font-size: 1rem;
}

.root:hover,
.root:focus,
.root:active {
  background-color: green;
  color: white;
}
