.root {
  margin: 0 10px;
  flex-grow: 1;
}

.input {
  padding: 8px;
  margin: 2px;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  width: 1rem;
  text-align: center;
}

