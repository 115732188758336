.root {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
}

.card {
  padding: 1rem 1rem 0;
  margin: 0 1rem;
}

.card img {
  width: 100%;
}

article {
  color: #ffffff;
  padding: 1rem;
  margin: 0 1rem;
  background: rgba(0, 0, 0, 0.6);
}
