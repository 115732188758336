html,
body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-palette-black: #000;
  --color-palette-white: #fff;
  --color-palette-green: #0f0;

  --button-color: var(--color-palette-black);
  --button-text-color: var(--color-palette-white);

  --animation-party: party 5s linear infinite;

  --animate-when-solved: none;
}

button,
button:visited {
  margin: 0.5rem;
  animation: var(--animate-when-solved);
  background-color: var(--button-color);
  border: 1px solid var(--button-text-color);
  color: var(--button-text-color);
  padding: 10px;
  text-decoration: none;
  width: 9rem;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
}

button:hover,
button:focus,
button:active {
  background-color: green;
  color: white;
}

a,
a:visited {
  color: white;
}
